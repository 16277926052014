<template>
<div>
	<div class="header">
		<div class="logo">
			<span class="mobile-menu-button" @click="toggleMobileMenu" v-if="user && store.project.internal_name && route.path.startsWith('/' + store.project.internal_name + '/')">
				<font-awesome-icon icon="fa-solid fa-bars" />
			</span>
			
			<nuxt-link :to="store.project.internal_name ? '/' + store.project.internal_name + '/ml/' : '/'">Almeta&nbsp;ML</nuxt-link>
		</div>
		<div class="top-menu" v-if="user">
			<a href="https://docs.almeta.cloud/" target="_blank">
				<font-awesome-icon icon="fa-regular fa-book" class="extra-margin" />
				Documentation
			</a>
			<nuxt-link to="/support/">
				<font-awesome-icon icon="fa-regular fa-circle-question" />
				Support
			</nuxt-link>
		</div>
		<div class="user-info" v-if="user && !route.path.startsWith('/account/setup/')">
			<ClientOnly>
				<div class="dropdown-element">
					<font-awesome-icon v-if="color_mode.value == 'dark'" icon="fa-regular fa-moon" />
					<font-awesome-icon v-else icon="fa-regular fa-sun-bright" />
					<font-awesome-icon icon="fa-solid fa-chevron-down" class="smaller" />
					
					<div class="dropdown-menu">
						<nuxt-link @click="color_mode.preference = 'system'" :class="{ selected: color_mode.preference == 'system' }">
							System Setting
						</nuxt-link>
						<nuxt-link @click="color_mode.preference = 'light'" :class="{ selected: color_mode.preference == 'light' }">
							Light
						</nuxt-link>
						<nuxt-link @click="color_mode.preference = 'dark'" :class="{ selected: color_mode.preference == 'dark' }">
							Dark
						</nuxt-link>
					</div>
				</div>
			</ClientOnly>
			
			<div v-if="store.project?.name" class="project-selector dropdown-element hide-on-mobile">
				<font-awesome-icon icon="fa-regular fa-cube" class="extra-margin" />
				{{ store.project.name }}
				<font-awesome-icon icon="fa-solid fa-chevron-down" class="smaller" />
				
				<div class="dropdown-menu">
					<nuxt-link v-for="project of projects" :key="project._id" :to="'/' + project.internal_name + '/ml/'">{{ project.name }}</nuxt-link>
				</div>
			</div>
			
			<div class="user-menu dropdown-element">
				<font-awesome-icon icon="fa-regular fa-user" />
				<span class="hide-on-mobile">&nbsp;{{ user.full_name || user.email }}&nbsp;</span>
				<font-awesome-icon icon="fa-solid fa-chevron-down" class="smaller" />
				
				<div class="dropdown-menu">
					<nuxt-link to="/account/">Account</nuxt-link>
					<nuxt-link :to="org_link" v-if="org_link">Billing</nuxt-link>
					<nuxt-link @click="logout" class="danger">Logout</nuxt-link>
				</div>
			</div>
		</div>
	</div>
	
	<div class="page-with-sidebar">
		<div class="sidebar" v-if="user && store.project.internal_name && route.path.startsWith('/' + store.project.internal_name + '/')">
			<nuxt-link v-for="link of sidebar_menu"
				:key="link.url"
				:to="'/' + store.project.internal_name + link.url"
				:class="{ selected: 
					(link.url != '/ml/' && route.path.startsWith('/' + store.project.internal_name + link.url))
					|| route.path == '/' + store.project.internal_name + link.url
				}"><font-awesome-icon :icon="[ 'fa-regular', link.icon ]" /> {{ link.name }}</nuxt-link>
		</div>
		<div class="sidebar" v-else-if="!route.path.startsWith('/login/') && !route.path.startsWith('/account/setup/')"></div>
		<div class="page">
			<slot />
		</div>
	</div>
	
	<div class="mobile-menu" v-if="show_mobile_menu && user && store.project.internal_name && route.path.startsWith('/' + store.project.internal_name + '/')">
		<nuxt-link v-for="link of sidebar_menu"
			:key="link.url"
			:to="'/' + store.project.internal_name + link.url"
			:class="{ selected: 
				(link.url != '/ml/' && route.path.startsWith('/' + store.project.internal_name + link.url))
				|| route.path == '/' + store.project.internal_name + link.url
			}"><font-awesome-icon :icon="[ 'fa-regular', link.icon ]" /> {{ link.name }}</nuxt-link>
	</div>
	
	<Modal v-if="!billing_plan && show_billing_plan_selector" @close="" wide="true" no-padding="true" hide-close-icon="false">
		<template #header>
			<h2>Select a Billing Plan</h2>
			<p>Choose a&nbsp;billing plan for&nbsp;your organization.</p>
		</template>
		<SelectMLPlan />
	</Modal>
</div>
</template>

<script setup lang="ts">
const user = useUser()
const store = useConsoleStore()
const route = useRoute()
const color_mode = useColorMode()
const show_mobile_menu = ref(false)
const { $csrfFetch } = useNuxtApp()

const billing_selector_closed_cookie = useCookie('billing_selector_closed', {
	path: '/',
})
billing_selector_closed_cookie.value = billing_selector_closed_cookie.value || null

const billing_selector_hide_cookie = useCookie('billing_selector_closed', {
	path: '/',
})
billing_selector_hide_cookie.value = billing_selector_hide_cookie.value || null

const billing_plan = computed(() => {
	if (!store.project?.organization_subscription_plans?.ml)
		return null
	return store.project?.organization_subscription_plans?.ml
})

const show_billing_plan_selector = ref(false)
if (
		user.value
	&&	!billing_plan.value
	&&	route.path != '/'
	&&	!route.path.startsWith('/account/')
	&&	!route.path.startsWith('/support/')
	&&	!route.path.startsWith('/org-')
	&&	!billing_selector_hide_cookie.value
)
{
	// setTimeout(() => show_billing_plan_selector.value = true, 1000)
	show_billing_plan_selector.value = true
}

function closeBillingSelector()
{
	show_billing_plan_selector.value = false
	billing_selector_closed_cookie.value = 'true'
	// alert('val' + billing_selector_closed_cookie.value)
}

const sidebar_menu = ref([
	{ url: '/ml/', name: 'Overview', icon: 'fa-chart-simple' },
	{ url: '/ml/models/', name: 'Models', icon: 'fa-chart-scatter-bubble' },
	{ url: '/ml/events/', name: 'Events', icon: 'fa-file-lines' },
	{ url: '/ml/web-tags/', name: 'Web tags', icon: 'fa-code' },
	{ url: '/ml/integrations/', name: 'Integrations', icon: 'fa-network-wired' },
	{ url: '/ml/catalog-data/', name: 'Catalog data', icon: 'fa-table-list' },
	{ url: '/ml/customers/', name: 'Customers', icon: 'fa-users' },
])

const organizations = ref([] as any[])
const projects = ref([] as any[])
const org_link = ref('')

const { data, pending, error, refresh } = await useFetch('/api/projects')
if (data.value)
{
	organizations.value = data.value.organizations || []
	projects.value = data.value.projects || []
	
	org_link.value = '/org-' + organizations.value[0]?._id + '/billing/'
}

async function logout()
{
	await $csrfFetch('/api/auth/logout', {
		method: 'POST'
	})
	
    user.value = null
    store.project = null
    store.organization = null
	
	await navigateTo('/login/', { external: true })
}

function toggleMobileMenu()
{
	if (show_mobile_menu.value)
		return hideMobileMenu()
	
	return showMobileMenu()
}

function showMobileMenu()
{
	show_mobile_menu.value = true
	setTimeout(() => window.addEventListener('click', hideMobileMenu), 100)
}

function hideMobileMenu()
{
	show_mobile_menu.value = false
	window.removeEventListener('click', hideMobileMenu)
}
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	align-items: flex-end;
	/* gap: 130px; */
	font-weight: 400;
	padding-bottom: 40px;
	
	.svg-inline--fa {
		margin: 0 2px;
		font-size: 0.875em;
		
		&.extra-margin {
			margin: 0 3px;
		}
		
		&.smaller {
			margin: 0 1px 1px 1px;
			font-size: 0.5625em;
		}
	}
	
	.mobile-menu-button {
		display: none;
		cursor: pointer;
		
		&:hover {
			color: $primary-blue;
		}
		
		@media (max-width: $breakpoint-phone) {
			display: inline-block;
			margin-right: 10px;
		}
	}
	
	.logo {
		/* width: 100%; */
		margin-left: 15px;
		padding-bottom: 7px;
		/* min-width: 185px; */
		/* max-width: 285px; */
		/* flex-grow: 1; */
		width: 235px;
		/* background: #ff0; */
		color: #000;
		font-size: 22px;
		font-weight: 700;
		cursor: default;
		white-space: nowrap;
		
		@media (max-width: 1000px) {
			width: 185px;
		}

		@media (max-width: 830px) {
			font-size: 18px;
		}
		
		@media (max-width: 730px) {
			flex-grow: 1;
		}
		
		@media (max-width: $breakpoint-phone) {
			margin-left: 0;
		}

		.dark-mode & {
			color: #fff;
		}
		
		a {
			color: #000;
			text-decoration: none;
			
			.dark-mode & {
				color: #fff;
			}
		}
	}
	
	.top-menu {
		flex-grow: 1;
		white-space: nowrap;
		padding-bottom: 10px;
		
		@media (max-width: 730px) {
			display: none;
		}

		a {
			margin: 0 40px 0 0;
			text-decoration: none;

			@media (max-width: 1146px) {
				margin: 0 30px 0 0;
				font-size: 15px;
			}
		}
	}
	
	.user-info {
		display: flex;
		gap: 40px;
		white-space: nowrap;
		margin-right: -10px;
		
		@media (max-width: 1146px) {
			gap: 20px;
		}
		
		@media (max-width: $breakpoint-phone) {
			gap: 10px;
		}
	}
}

.page-with-sidebar {
	display: flex;
	
	.sidebar{
		width: 250px;
		/* min-width: 200px; */
		/* max-width: 300px; */
		/* flex-grow: 1; */
		flex-shrink: 0;
		padding-top: 50px;
		
		@media (max-width: 1000px) {
			width: 200px;
		}
		
		@media (max-width: $breakpoint-phone) {
			display: none;
		}
		
		a {
			display: block;
			max-width: 160px;
			padding: 11px 15px;
			text-decoration: none;
			font-weight: 400;
			border-radius: 9px;
			
			@media (max-width: 1000px) {
				max-width: 140px;
			}
			
			&.selected {
				color: #fff;
				background: #0d55ff;
			}
			
			.svg-inline--fa {
				min-width: 20px;
				margin-right: 7px;
			}
		}
	}
	
	.page {
		flex-grow: 1;
	}
}

.mobile-menu {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 200px;
	padding: 10px;
	background-color: $body-background;
	box-shadow: 0px 9px 28px 0px rgba(0, 0, 0, 0.5);
	
	a {
		display: block;
		max-width: 160px;
		padding: 11px 15px;
		text-decoration: none;
		font-weight: 400;
		border-radius: 9px;
		
		@media (max-width: 1000px) {
			max-width: 140px;
		}
		
		&.selected {
			color: #fff;
			background: #0d55ff;
		}
		
		.svg-inline--fa {
			min-width: 20px;
			margin-right: 7px;
		}
	}
}
</style>